/* media query for extra small devices */
@media only screen and (max-width:767px) {
    .login-back {
        background: #F7F8FA;
        text-align: center;
    }
    .logo-img{position: relative !important;}
    .login-back .back-top {
        background: #F7F8FA;
    }
    .login-back .back-bottom {
        background: #F7F8FA;
    }
    .login-form {
        width: 100%;
        padding: 20px;
    }

    .login-form-back {
        background: none;
        width: 100%;
        height: auto;
        margin: auto;
        padding: 20px;
        border: 1px solid #e6e5e5;
        box-shadow: 1px 1px 10px #0000002b;
        border-radius: 24px;
        margin-top: 1rem;
        background: #fff;
    }

    .register-form-back {
        background: none;
        width: 100%;
        height: auto;
        margin: auto;
        padding: 20px;
        border: 1px solid #e6e5e5;
        box-shadow: 1px 1px 10px #0000002b;
        border-radius: 24px;
        margin-top: 1rem;
        background: #fff;
    }

    .login-form-back h2 {
        font-size: 1.8rem;
    }

    .login-form-back p {
        font-size: 0.8rem;
    }

    .login-form-back label {
        font-size: 0.843rem;
    }

    .register-form-back h2 {
        font-size: 1.8rem;
    }

    .register-form-back p {
        font-size: 0.8rem;
    }

    .register-form-back label {
        font-size: 0.843rem;
    }

    .sign-up-link {
        margin-top: 1rem;
    }

    .forgot-form-back {
        text-align: left;
        background: none;
        width: 100%;
        height: auto;
        margin: auto;
        padding: 20px;
        border: 1px solid #e6e5e5;
        box-shadow: 1px 1px 10px #0000002b;
        border-radius: 24px;
        margin-top: 1rem;
        background: #fff;
    }

    .forgot-form {
        width: 100%;
        padding: 20px;
    }

    .forgot-form h2 {
        font-size: 1.8rem
    }

    .forgot-form p {
        font-size: 0.8rem;
    }

    .remember {
        min-width: 300px;
    }
    .search-form{display: none;}
    img.close-icon {
        display: block;
    }
}

@media only screen and (max-width:375px) {
    .login-back img {
        width: 175px;
    }
    .remember {
        min-width: 230px;
    }
    .login-form-back{margin-top: 4rem;}
    .register-form-back{margin-top: 80px;}
    
}

@media only screen and (max-width: 991px) {
    .page-wrapper.inactive{margin-left: 0px;}
    .page-wrapper{margin-left: 0px !important;}
    .sidebar-menu{margin-left: -260px;}
    .sidebar-menu.inactive{margin-left: 0px;}
    .topbar-menu{padding-left: 125px;}
    span.product-btn{padding:10px 45px;}
    .sidebar-menu.inactive .toggle-menu .menu-icon {
        display: none;
    }
    .sidebar-menu .toggle-menu .close-icon {
       display: none;
    }
    .sidebar-menu.inactive .toggle-menu .close-icon {
        right: -50px;
        display: block;
    }
    .body-overlay.inactive {
        width: 100vw;
        height: 100vh;
        position: fixed;
        z-index: 1;
        top: 0;
        overflow: hidden;
        background: rgba(0, 0, 0, 0.5);
      }
      .customerlist-leftsection {
        width: 100%;
        position: relative;
    }
}

/* media query for ipad devices */
@media screen and (min-width: 768px) and (max-width: 1023px) {
    .login-back .back-top {
        width: 340px;
        height: 250px;
    }
    .login-back .back-bottom {
        width: 145px;
        height: 275px;
    }
    .page-wrapper.inactive{margin-left: 0px;}
    .page-wrapper{margin-left: 0px !important;}
    .sidebar-menu{margin-left: -260px;}
    .sidebar-menu.inactive{margin-left: 0px;}
    .topbar-menu{padding-left: 125px;}
    img.close-icon {
        display: block;
    }
    .sidebar-menu.inactive .toggle-menu .menu-icon {
        display: none;
    }
    .sidebar-menu .toggle-menu .close-icon {
       display: none;
    }
    .sidebar-menu.inactive .toggle-menu .close-icon {
        right: -50px;
        display: block;
    }
    .body-overlay.inactive {
        width: 100vw;
        height: 100vh;
        position: fixed;
        z-index: 1;
        top: 0;
        overflow: hidden;
        background: rgba(0, 0, 0, 0.5);
      }
}


/* media query for medium devices */
@media screen and (min-width: 1024px) and (max-width: 1200px) {
    .login-back .back-top {
        width: 340px;
        height: 250px;
    }
    .login-back .back-bottom {
        width: 145px;
        height: 275px;
    }
    .page-wrapper.inactive{margin-left: 0px;}
    .page-wrapper{margin-left: 0px !important;}
    .sidebar-menu{margin-left: -260px;}
    .sidebar-menu.inactive{margin-left: 0px;}
    .topbar-menu{padding-left: 125px;}
    img.close-icon {
        display: none;
    }
    .sidebar-menu.inactive .toggle-menu .menu-icon {
        display: none;
    }
    .sidebar-menu .toggle-menu .close-icon {
       display: none;
    }
    .sidebar-menu.inactive .toggle-menu .close-icon {
        right: -50px;
        display: block;
    }
    .body-overlay.inactive {
        width: 100vw;
        height: 100vh;
        position: fixed;
        z-index: 1;
        top: 0;
        overflow: hidden;
        background: rgba(0, 0, 0, 0.5);
      }
}


/* media query for large devices */
@media screen and (min-width: 481px) and (max-width: 768px) {}


@media (min-width: 1600px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
      max-width: 1580px !important;
    }
  }