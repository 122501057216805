body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background: #fff;
  cursor: pointer;
}

a {
  cursor: pointer;
  text-decoration: none;
}

::placeholder {
  color: #2d3e5080;
}

input[type="email" i] {
  text-transform: lowercase;
}

.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%);
}

.btn-primary {
  background: #244da1;
  border: none;
  width: 100%;
  height: 40px;
}

.btn-primary:hover {
  color: #fff;
  background-color: #1d4392;
  border-color: #244da1;
}

.btn-secondary {
  height: 40px;
  background: #bf2527;
  border: none;
  width: 100%;
}

.btn-secondary:active,
.btn-secondary:hover,
.btn-secondary:focus {
  background: #a52022;
}

.btn-outline-primary {
  border-color: #244da1;
  color: #244da1;
  font-weight: 500;
  height: 45px;
  width: 100%;

}


.btn-outline-primary:hover {
  border-color: #244da1;
  background: #244da1;
  color: #fff;
  font-weight: 500;
  height: 45px;
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #fff;
  background-color: gray;
  border: gray;
}

button.btn.btn-secondary.isDisabled.btn.btn-primary {
  background: #bf5257a8;
}

th {
  white-space: nowrap;
  color: #fff;
  font-weight: 400;
}

.form-floating>.form-control,
.form-floating>.form-select {
  height: calc(3rem + 0px);
  line-height: 1;
  /* width: 100%;  Adjusted this value */

  width: 100%;

  /* width: fit-content; */
  /* overflow: auto; */
}

.form-floating>label {
  padding: 0.75rem 0.75rem;
  font-size: clamp(12px, .85vw, 15px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;

}

.row-styling {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(4, 1fr);
  width: min(100%, 70rem);
  margin-inline: auto;
}

.col {
  padding: 5px;
}

.form-floating>.form-select {
  padding-top: 1.4rem !important;
  text-transform: capitalize;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

#floatingSelectGrid+label {
  font-size: 14px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #244da180;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #244da180;
}

.page-wrapper {
  margin-left: 260px;
  padding: 100px 10px 48px 10px;
  transition: .1s ease-in-out;
}

.card {
  box-shadow: 2px 12px 21px #244da138;
}

.card-header {
  background-color: #fff;
  border-bottom: 1px solid #f6f6f6;
  font-size: 1.2rem;
  padding: 1rem;
}

.table>thead {
  background: #244da1;
  line-height: 33px;
  /* overflow-x: scroll;
  width: 100%;
  display:block; */
}

.table>tbody>tr:nth-child(odd) {
  background: #f6f6f6;
  line-height: 33px;
  cursor: pointer;
}

.table>tbody>tr:nth-child(even) {
  background: #fff;
  line-height: 33px;
  cursor: pointer;
}

table.mdb-dataTable thead th,
table thead.mdb-dataTable-head th {
  font-weight: 400;
  color: #fff;
}

.table td {
  white-space: nowrap;
}

.modal-header {
  background: #bf2527;
  color: #fff;
  padding: 0.5rem 1rem;
}

.modal-title {
  font-size: 1rem;
}

.mdb-datatable {
  overflow: auto;
}

.mdb-datatable tr {
  white-space: nowrap;
}

.mdb-datatable-entries {
  display: none;
}

table.mdb-dataTable thead .sorting:before {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

table.mdb-dataTable thead>tr>th.sorting_asc::before {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

table.mdb-dataTable thead>tr>th.sorting_desc::before {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

table.mdb-dataTable thead .sorting:after {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

table.mdb-dataTable thead>tr>th.sorting_asc::after {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

table.mdb-dataTable thead>tr>th.sorting_desc::after {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.page-wrapper.inactive {
  margin-left: 100px;
  transition: .1s ease-in-out;
}

svg.MuiSvgIcon-root.close-icon {
  display: none;
  width: 45px;
  height: 45px;
  top: -9px;
  position: relative;
  left: 10px;
}

svg.MuiSvgIcon-root.close-icon path {
  fill: #fff;
}

.MuiAutocomplete-popupIndicator {
  display: none !important;
}

input#combo-box-demo {
  z-index: 1;
  padding: 3.5px 4px 0.5px 12px;
  height: 36px;
}

/* datatable css */
.datatable_section {
  width: 100%;
  background: #fff;
  padding-top: 16px;
  height: 400px;
}

.css-imf0nl-MuiDataGrid-root .MuiDataGrid-cellContent {
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.MuiDataGrid-root {
  padding: 10px;
  padding: 10px;
  border: 1px solid #fff !important;
  border-radius: 6px !important;
}

.MuiDataGrid-toolbarContainer {
  flex-direction: row-reverse;
  position: relative;
  top: -5px;
}

.MuiDataGrid-columnHeaders {
  background: #244da1;
  color: #fff;
}

.MuiOutlinedInput-root {
  height: 48px;
}

input#asynchronous-demo {
  z-index: 999;
  padding: 10px 3px 0px 12px !important;
}

.css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root:hover {
  color: #fff;
}

/* .MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon .MuiInputLabel-root{
top:-2px !important
} */
.MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon .MuiInputLabel-root.MuiInputLabel-shrink {
  top: 12px !important;
  font-size: 1.2rem !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.23) !important;
  border-width: 1px !important;
}

.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.MuiAutocomplete-endAdornment {
  z-index: 1;
}

.MuiInputAdornment-root {
  z-index: 999;
}

.MuiDataGrid-root {
  border: none;
}

.MuiAutocomplete-popupIndicator {
  display: none;
}

.MuiDataGrid-toolbarContainer {
  position: relative;
  top: -14px;
}

.user-role-table .MuiFormControl-root {
  position: absolute;
  left: 445px;
}

.MuiFormControl-root.MuiTextField-root.css-qu8si5-MuiFormControl-root-MuiTextField-root-MuiDataGrid-toolbarQuickFilter {
  position: relative;
  left: 975px;
}

.MuiFormControl-root.MuiTextField-root.css-qu8si5-MuiFormControl-root-MuiTextField-root-MuiDataGrid-toolbarQuickFilter {
  position: relative;
  left: 975px;
}

.active.MuiDataGrid-cell {
  color: #266e28;
  font-weight: 500;
}

.inactive.MuiDataGrid-cell {
  color: #bf2527;
  font-weight: 500;
}

.css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 2px !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  top: 0px !important;
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  top: 17px !important;
  font-size: 1.2rem !important;
}

legend {
  float: left !important;
}

.form-bg .MuiAutocomplete-inputFocused {
  z-index: 1 !important;
  padding: 18px 14px 0px 14px !important;
}

.css-1laqsz7-MuiInputAdornment-root {
  z-index: 999;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100%;
}

.btn-primary:focus {
  box-shadow: none;
}

.btn-secondary:focus {
  box-shadow: none;
}

.form-check-input:checked {
  background-color: #244da1;
  border-color: #244da1;
}


.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #bf2527;
  background-color: #fff;
  border-color: #fff #fff #bf2527;
}

.nav-tabs .nav-link {
  border: 5px solid transparent;
  color: #2d3e50;
  font-weight: 500;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: #fff #fff #bf2527;
  isolation: isolate;
  color: #bf2527;
}

.form-control:focus {
  box-shadow: none;
}

.Toastify__toast-container {
  width: auto;
}

p.error-message {
  color: #ed1b1d;
  margin-top: 4px;
  margin-bottom: 0px;
  margin-left: 11px;
}

.help.is-danger {
  margin-left: 8px;
  color: #ed1b1d;
  font-size: 16px;
}

p.help.is-danger:before {
  display: inline;
  content: " * ";
  font-size: 16px;
  font-weight: 600;
}

/* Background css */
p.errmsg {
  background: #ff454880;
  padding: 6px;
  text-align: center;
  color: #bf2527;
}

.login-back {
  background: #f7f7f7;
  min-width: 100%;
  height: 125vh;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
}

.login-back img {
  width: 230px;
  height: auto;
  padding-top: 32px;
  padding-left: 32px;
  cursor: pointer;
  position: absolute;
}

.login-back .back-top {
  background: url("/src/assets/back_top.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 450px;
  height: 350px;
  position: absolute;
  right: 0;
}

.login-back .back-bottom {
  background: url("/src/assets/back_bottom.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 200px;
  height: 373px;
  position: absolute;
  bottom: 20%;
}

/* Login css */
.login-form {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%)
}

.login-form h2 {
  text-align: center;
  font-size: 40px;
  color: #244DA1;
}

.login-form form img {
  width: auto;
}

.login-form-back {
  background: url("/src/assets/login-back.png");
  background-repeat: no-repeat;
  width: 500px;
  height: 653px;
  margin: auto;
  padding: 160px 49px 160px 75px;
}

.sign-up-link {
  margin: auto;
  text-align: center;
}

.login-form input[type="text"] {
  height: 52px;
  border-radius: 100px;
  box-shadow: 0px 6px 20px #c7d0f880;
  border: none;
  padding: 0px 60px 0px 24px;
}

.login-form input[type="email"] {
  height: 52px;
  border-radius: 100px;
  box-shadow: 0px 6px 20px #c7d0f880;
  border: none;
  padding: 0px 60px 0px 24px;
}

.login-form input[type="password"] {
  height: 52px;
  border-radius: 100px;
  box-shadow: 0px 6px 20px #c7d0f880;
  border: none;
  padding: 0px 60px 0px 24px;
}

.remember {
  display: flex;
  justify-content: space-between;
  color: #2d3e5080;
  min-width: 380px;
}

.remember a {
  color: #2d3e5080;
}

.login-btn {
  display: flex;
  justify-content: flex-end;
}

.login-btn button {
  border-radius: 6px;
  font-weight: 500;
  width: 60%;
}

.username {
  position: relative;
  margin-bottom: 1rem;
}

.username img {
  position: absolute;
  right: 17px;
  top: -17px;
}

.password {
  position: relative;
  margin-bottom: 1rem;
}

.password img {
  position: absolute;
  right: 17px;
  top: -21px;
}

.sign-up-link p {
  color: #707070;
  margin-bottom: 0rem;
}

.sign-up-link a {
  font-weight: 700;
  font-size: 14px;
  text-decoration: none;
}

.forgot:hover {
  color: #707070;
}

/* Registration css */
.register-form-back {
  background: url("/src/assets/register-back.png");
  background-repeat: no-repeat;
  width: 536px;
  height: 790px;
  margin: auto;
  padding: 195px 50px 69px 75px;
}

.register-form-back .username img {
  position: absolute;
  right: 17px;
  top: -14px;
}

.register-form-back .password img {
  position: absolute;
  right: 17px;
  top: -22px;
}

.username label {
  color: #2d3e50cc;
  font-size: 16px;
  position: absolute;
  z-index: 2;
  left: 20px;
  top: 14px;
  padding: 0 2px;
  pointer-events: none;
  transition: transform 100ms ease;
}

.password label {
  color: #2d3e50cc;
  font-size: 16px;
  position: absolute;
  z-index: 2;
  left: 20px;
  top: 14px;
  padding: 0 2px;
  pointer-events: none;
  transition: transform 100ms ease;
}

/* Forgot css */
.forgot-form {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.forgot-form-back {
  background: url("/src/assets/forgot-back.png");
  background-repeat: no-repeat;
  width: 500px;
  height: 635px;
  margin: auto;
  padding: 214px 50px 69px 75px;
}

.forgot-form-back .username img {
  position: absolute;
  right: 17px;
  top: -14px;
}

/* Topbar css */
.topbar-menu {
  width: 100%;
  height: 80px;
  background: #fff;
  box-shadow: 1px 1px 10px #00000030;
  display: flex;
  align-items: center;
  padding-left: 400px;
  position: fixed;
  z-index: 999;
}

.topbar-menu .toggle-menu {
  margin-left: 340px;
}

.notify {
  margin-left: auto;
  padding: 20px;
}

.notify i {
  color: #2d3e50;
  background: #f6f6f6;
  padding: 10px 10px;
  border-radius: 6px;
}

/* Sidebar css */
.sidebar-menu {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 260px;
  background: #fff;
  height: 100vh;
  border-right: 1px solid #e8e8e8;
  box-shadow: 1px 1px 10px #00000030;
  transition: .1s ease-in-out;
}

.top-section {
  text-align: center;
  border-bottom: 1px solid #eaeaea;
  padding: 11px;
}

.sidebar-menu.inactive {
  width: 100px;
  transition: .1s ease-in-out;
}

.sidebar-menu.inactive .top-section .logo img {
  width: 84px;
  padding: 17px 0px;
}

.sidebar-menu.inactive .sidebar-item {
  width: 57px;
  margin-left: 11px;
  position: relative;
}

.sidebar-menu .top-section .logo img {
  width: 200px;
}

.sidebar-menu.inactive .sidebar-item.open .sidebar-content a {
  width: 240px;
}

.sidebar-menu.inactive .sidebar-item.open .sidebar-content {
  position: absolute;
  /* background: #2d3e50; */
  left: 61px;
  top: 10px;
  opacity: 0;
  /* padding: 10px;
  border: none;
  border-radius: 6px;
  box-shadow: 1px 1px 10px #2d3e5061; */
  transition: .1s ease-in-out;
}

.sidebar-menu.inactive .nav_sidebar_menu {
  overflow: inherit;
}

.single-menu {
  display: flex;
}

/* .sidebar-menu.inactive .sidebar-item.plain span{display: none;} */
.sidebar-menu.inactive a.sidebar-item.plain span {
  display: none;
}

.sidebar-menu.inactive .sidebar-item .sidebar-title span {
  display: none;
}

.sidebar-menu.inactive .sidebar-item .sidebar-content span {
  display: block !important;
  color: #fff;
}

.sidebar-menu.inactive .sidebar-item.open>.sidebar-content a:before {
  background: #fff;
}

.sidebar-menu.inactive .sidebar-item.open>.sidebar-title .toggle-btn {
  transform: rotate(-90deg);
}

.sidebar-menu.inactive .sidebar-item.open .sidebar-content a.active {
  background: #5e6c7a;
}

/* .sidebar-menu.inactive:hover {width: 250px;} */
.toggle-menu {
  position: absolute;
  right: -55px;
  top: 29px;
  transition: .2s ease-in-out;
  cursor: pointer;
}

.nav_sidebar_menu {
  list-style: none;
  padding: 30px 5px 200px;
  height: 100vh;
  overflow: scroll;
}

.nav_sidebar_menu li {
  padding: 6px 0px;
}

.nav_sidebar_menu a {
  display: flex;
}

.nav_sidebar_menu a span {
  padding-left: 20px;
  color: #2d3e50;
  font-size: 18px;
  font-weight: 500;
}

.nav_sidebar_menu a .toggle-btn {
  margin-left: auto;
}

.nav_sidebar_menu a.active span {
  color: #fff;
}

.nav_sidebar_menu a.active {
  background: #bf2527;
  border-radius: 6px;
}

.menu-icon img {
  width: 20px;
  height: auto;
}

ul.sub-menu li a {
  color: #2d3e50;
  font-size: 14px;
  font-weight: 500;
}

ul.sub-menu {
  list-style-type: none;
  padding-left: 1rem;
  max-height: 0;
  overflow: hidden;
  transition: max-height .3s ease-in-out;
  border-left: 1px solid #939393;
  margin-left: 22px;
  position: relative;
}

ul.sub-menu.active {
  max-height: max-content;
}

ul.sub-menu li:before {
  content: '-';
  position: absolute;
  font-size: 40px;
  margin-top: -22px;
  margin-left: -17px;
}

ul.sub-menu.active a.active {
  padding: 6px;
  color: #fff;
}

.sidebar-menu.inactive .nav_sidebar_menu li a {
  width: 55px;
  border: 1px solid #f2f2f2;
  border-radius: 6px;
}

.sidebar-menu.inactive .nav_sidebar_menu li a.active {
  width: 55px;
  box-shadow: 1px 1px 10px #00000050;
}

.sidebar-menu.inactive .nav_sidebar_menu li a.active span {
  color: #000;
}

.sidebar-menu .MuiSvgIcon-root {
  fill: #2d3e50;
}

.nav_sidebar_menu a.active .MuiSvgIcon-root {
  fill: #fff;
}

.sidebar-menu.inactive li:hover a {
  background: #bf2527;
}

.sidebar-menu.inactive li:hover .MuiSvgIcon-root {
  fill: #fff;
}

.sidebar {
  width: 260px;
  flex-shrink: 0;
  background-color: rgba(22, 22, 22, 1);
  height: 100%;
  overflow: auto;
}

.sidebar-item {
  padding: .75em 1em;
  display: block;
  transition: background-color .15s;
  border-radius: 5px;
  cursor: pointer;
}

.sidebar-item:hover {
  background-color: rgba(255, 255, 255, .1);
}

.sidebar-title {
  display: flex;
  font-size: 1.2em;
  justify-content: space-between;
}

.sidebar-title span i {
  display: inline-block;
  width: 1.5em;
}

.sidebar-title .toggle-btn {
  cursor: pointer;
  transition: transform .3s;
  margin-left: auto;
}

.sidebar-item.open>.sidebar-title .toggle-btn {
  transform: rotate(180deg);
}

.sidebar-content {
  padding-top: .25em;
  height: 0;
  overflow: hidden;
  border-left: 1px solid #000;
  display: none;
  margin-left: 10px;
}

.sidebar-item.open>.sidebar-content {
  height: auto;
  display: block;
}

.sidebar-item.plain {
  color: #fff;
  text-decoration: none;
}

.sidebar-item.plain:hover {
  text-decoration: underline;
}

.sidebar-item.plain i {
  display: inline-block;
  width: 1.7em;
}

.sidebar-title .menu-icon {
  margin-top: -3px;
}

.sidebar-title span {
  padding-left: 20px;
  color: #2d3e50;
  font-size: 17px;
  font-weight: 500;
}

.sidebar-item.open>.sidebar-content a span {
  font-size: 12px;
  padding-left: 16px;
}

.sidebar-item.open>.sidebar-content a:before {
  content: '';
  position: relative;
  font-size: 30px;
  margin-top: 8px;
  margin-left: -33px;
  width: 17px;
  height: 2px;
  background: black;
}

.sidebar-item.open>.sidebar-content a {
  margin-left: 16px;
}

.sidebar-item:nth-child(10) .sidebar-content .sidebar-item:nth-child(6) {
  margin-left: -21px;
}

.sidebar-item:nth-child(10) .sidebar-content .sidebar-item:nth-child(6) .sidebar-content {
  border-left: none;
  margin-left: 36px;
}

.sidebar-item:nth-child(10) .sidebar-content .sidebar-item:nth-child(6) .sidebar-title span {
  margin-left: -5px;
  font-size: 15px;
}

.sidebar-item:nth-child(10) .sidebar-content .sidebar-item:nth-child(7) {
  margin-left: -21px;
}

.sidebar-item:nth-child(10) .sidebar-content .sidebar-item:nth-child(7) .sidebar-content {
  border-left: none;
  margin-left: 36px;
}

.sidebar-item:nth-child(10) .sidebar-content .sidebar-item:nth-child(7) .sidebar-title span {
  margin-left: -5px;
  font-size: 15px;
}

/* Userprofile css */
.dropdown span {
  margin-left: 11px;
  text-transform: lowercase;
  color: #000;
  margin-right: 8px;
}

.dropdown-header {
  padding: 12px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-body {
  padding: 5px;
  border-top: 1px solid #E5E8EC;
  display: none;
}

.dropdown-body.open {
  display: block;
  position: absolute;
  background: #ffffff;
  right: 8px;
  box-shadow: 1px 1px 10px #00000030;
  border-radius: 6px;
}

.dropdown-item img {
  width: 18px;
}

.topbar-menu i {
  font-size: 15px;
  margin-left: 0px;
  color: #000;
}

.dropdown-item {
  padding: 13px 42px 13px 20px;
}

.dropdown-item span {
  position: relative;
  top: 2px;
  left: 10px;
}

.dropdown-item:hover {
  cursor: pointer;
}

.dropdown-item:active {
  color: #000;
  text-decoration: none;
  background-color: #a5b7ca;
}

.dropdown-item-dot {
  opacity: 0;
  color: #91A5BE;
  transition: all .2s ease-in-out;
}

.dropdown-item-dot.selected {
  opacity: 1;
}

.icon {
  font-size: 13px;
  color: #91A5BE;
  transform: rotate(0deg);
  transition: all .2s ease-in-out;
}

.icon.open {
  transform: rotate(180deg);
}

.profile-pic {
  background: #f6f6f6;
  padding: 6px;
  border-radius: 50%;
}

.profile-pic path {
  fill: #000
}

.profile-pic svg {
  width: 35px;
  height: 35px;
}

.dropdown-body.open i.fa.fa-sign-out {
  margin-left: -18px;
  font-size: 19px;
  margin-right: 14px;
}

/* Search css */
.search-form form {
  position: relative;
}

.search-form input {
  height: 48px;
  background: #f6f6f6;
  border: none;
  border-radius: 5px;
  padding: 0px 20px 0px 45px;
}

.search-form form span {
  color: #000;
  position: absolute;
  top: 13px;
  left: 15px;
}

/* Dashboard css */
@keyframes fadeIn {
  from {
    top: 20%;
    opacity: 0;
  }

  to {
    top: 100;
    opacity: 1;
  }

}

@-webkit-keyframes fadeIn {
  from {
    top: 20%;
    opacity: 0;
  }

  to {
    top: 100;
    opacity: 1;
  }

}

.dashboard-section {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  animation: fadeIn 1000ms ease;
  -webkit-animation: fadeIn 1000ms ease;

}

.dashboard-section h1 {
  font-size: 70px;
  margin-bottom: 0;
  line-height: 1;
  font-weight: 700;
  color: #244da1;
}

.dot {
  color: #4FEBFE;
}

.dashboard-section p {
  text-align: center;
  margin: 18px;
}

/* Order Page Css */
.orderform {
  background: #fff;
  padding: 24px;
  box-shadow: 1px 1px 10px #00000020;
  border-radius: 6px;
}

.orderform h2 {
  font-size: 22px;
  color: #2d3e50;
  margin-bottom: 0.5rem;
}

.orderform p,
h5 {
  margin-bottom: 0rem;
}

.orderform .accordian-plus {
  position: absolute;
  left: -58px;
  background: #fff;
  box-shadow: 1px 1px 10px #00000020;
  padding: 5px 5px 0px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  line-height: 26px;
  font-size: 30px;
}

.orderform span {
  font-size: 18px;
  font-weight: 500;
}

.orderform span svg {
  color: #000 !important;
}

/* .orderform input,
select {
  height: 40px;
} */

.form-select {
  color: #6a6a6a;
}

input.form-check-input {
  width: 1rem;
  height: 1rem;
}

.orderform h5 {
  font-size: 1.1rem;
}

.next-btn i {
  padding-left: 6px;
}

.next-btn {
  text-align: right;
  color: #244da1;
  font-weight: 600;
  font-size: 15px;
  padding: 10px 10px 0px 10px;
  display: flex;
  justify-content: end;
}

.next-btn p {
  background: #fff;
  padding: 6px 9px 6px 6px;
  margin: 3px;
  border-radius: 50%;
  box-shadow: 1px 1px 10px #00000030;
}

.orderform-sidesection p {
  font-size: 11px;
}

.orderform-sidesection fieldset {
  margin-top: 6px;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.map-container {
  margin-top: 1.5rem;
  background: #fff;
  padding: 1.2rem;
  border-radius: 6px;
  /* height: 400px; */
}

.select-time {
  background: #fffcfc;
  padding: 10px;
  border-radius: 4px;
  justify-content: space-between;
  border: 1px solid #cbcbcb;
}

.autosuggest_box input {
  cursor: pointer;
}

.autosuggest_box .dHyvRM>.wrapper {
  border-radius: 4px;
  border: 1px solid #cfcfcf;
  z-index: 99;
}

.autosuggest_box .dHyvRM>.wrapper:hover {
  box-shadow: none;
}

.autosuggest_box .kdWFRH .selected .result-wrapper {
  background-color: #dddddd;
  cursor: pointer;
}

.autosuggest_box .result-wrapper {
  display: flex;
  background: #f1f1f1;
  justify-content: space-between;
  padding: 5px 12px;
  margin-right: 13px;
}

.autosuggest_box svg.sc-bdvvtL.ekwNMM.search-icon {
  display: none;
}

.autosuggest_box .hssVVu {
  min-height: 57px;
}

fieldset {
  border: 1px solid #c2c2c2;
  padding: 10px 20px;
  border-radius: 6px;
  background: #fff;
}

.fieldset-row {
  margin-top: -16px;
}

.fieldset-inner {
  border: 1px solid #c2c2c2;
  padding: 0px 0px 0px 9px;
  /* height: 48px; */
  border-radius: 6px;
  background: #fff;
}

.fieldset-inner-email {
  border-bottom: 1px solid #c2c2c2;
  padding: 9px 9px 9px 9px;
  /* height: 48px; */
  /* border-radius: 6px; */
  background: #fff;
}

.fieldset-inner .floating-checbox {
  position: relative;
  top: -9px;
  margin-left: 0;
  margin-right: auto;
}

.fieldset-inner .floating-checboxemail {
  position: relative;
  margin-top: 10px;
  left: 7px;
  margin-bottom: 10px;
  /* align-items: start; */
}

fieldset .label-heading {
  position: relative;
  bottom: 24px;
  background: #fff;
  text-align: center;
  padding: 2px 8px;
  color: #244da1;
  font-weight: 500;
  font-size: 14px;
  border-radius: 6px;
}

.fieldset-inner .label-heading {
  color: #000;
  font-size: 11px;
  bottom: 14px;
}

.less_load_table {
  background: rgb(36 77 167 / 50%);
  border: none;
  box-shadow: 1px 1px 10px #00000033;
  /* display: inline-block; Display as inline-block to fit content */
  /* width: auto; Automatically adjust width based on content */
  /* height: auto; Automatically adjust height based on content */

}

.less_load_table .table>thead {
  background: #244DA1;
  line-height: 33px;
  border: #1b408b;
}

.full_load_table {
  background: #266e2880;
  border: none;
  box-shadow: 1px 1px 10px #00000033;
  /* display: inline-block; Display as inline-block to fit content */
  /* width: auto; Automatically adjust width based on content */
  /* height: auto; Automatically adjust height based on content */

}

.full_load_table .table>thead {
  background: #266e28;
  line-height: 33px;
  border: #146016;
}

.heavy_load_table {
  background: #a5202280;
  border: none;
  box-shadow: 1px 1px 10px #00000033;
  /* display: inline-block; Display as inline-block to fit content */
  /* width: auto; Automatically adjust width based on content */
  /* height: auto; Automatically adjust height based on content */
}

.heavy_load_table .table>thead {
  background: #a52022;
  line-height: 33px;
  border: #7d181a;
}

.orderform-sidesection {
  background: #fff;
  padding: 16px 24px;
  box-shadow: 1px 1px 10px #00000020;
  border-radius: 6px;
}

.quote-table {
  height: 317px;
  overflow: auto;
}

span.product-btn {
  background: #244da180;
  padding: 10px 20px;
  color: #244da1;
  border-radius: 6px;
}

.orderform-sidesection .form-check label {
  color: #007204;
  font-size: 11px;
  font-weight: 600;
}

.orderform-sidesection button {
  width: 100%;
  padding: 6px;
  border-radius: 6px;
  border: none;
  white-space: nowrap;
  /* Prevent text from wrapping */
  overflow: hidden;
  /* Hide overflowing text */
  text-overflow: ellipsis;
  /* Display an ellipsis (...) for overflow */

}

.orderform-sidesection .to {
  background: #fff;
  color: #007204;
  border: 2px solid #007204;
  font-weight: 500;
}

.orderform-sidesection .from {
  background: #fff;
  color: #244da1;
  border: 2px solid #244DA1;
  font-weight: 500;
}

.orderform-sidesection .btm {
  background: #BF252780;
  color: #bf2527;
  font-weight: 500;
}

.orderform-sidesection label {
  font-size: 13px;
  font-weight: 500;
}

.quote-list {
  margin-top: -10px;
  margin-bottom: -10px;
}

.quote-list .RadioButton {
  padding: 0px 10px 10px 0px;
}

.radio-btn-container {
  display: flex;
  margin-bottom: 1rem;
}

.RadioButton {
  display: flex;
  align-items: center;
  font-size: 17px;
  font-weight: 500;
  margin-right: 20px;
}

.RadioButton input {
  margin-right: 6px;
  width: 20px;
  height: 20px;
}

.usefuel-yes {
  display: flex;
  margin-right: 20px;
}

.usefuel-no {
  display: flex;
  margin-right: 20px;
}

.view-detail {
  background: #244da180;
  padding: 13px;
  border-radius: 6px;
}

.notes {
  padding: 11px 14px;
  background: #fff;
  border-radius: 6px;
  color: #244da1;
  text-align: center;
  cursor: pointer
}

.notes-data {
  padding: 11px 14px;
  background: #244da1;
  border-radius: 6px;
  color: #fff;
  text-align: center;
  cursor: pointer
}

.basic-multi-select .css-1rhbuit-multiValue {
  background-color: #244da1cc;
  border-radius: 4px;
}

.basic-multi-select .css-12jo7m5 {
  color: #fff;
}

.basic-multi-select .css-tj5bde-Svg {
  fill: #464646;
}

.basic-multi-select .css-xb97g8:hover {
  background-color: #244da1;
  color: #fff;
}

/* Product description css */
.product-btn {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;

}

#product-desp {
  max-width: 1350px;
}

.product-input {
  display: flex;
  overflow: auto;
  padding-bottom: 7px;
}

.product-input .product-input-box {
  padding: 9px;
}

.product-input-box input {
  height: 40px;
  border: 1px solid #d5d0d0;
  border-radius: 6px;
  padding: 10px;
}

.addaccessrial_btn {
  text-align: end;
  background: rgb(191, 37, 39);
  width: 50px;
  margin-left: auto;
  border-radius: 6px;
}

.addaccessrial_btn .btn {
  color: #fff
}

.section_back {
  background: rgba(37, 79, 162, 0.28);
  padding: 24px 7px 2px;
  border-radius: 6px;
  margin: 10px 0px;
}

/* Email Notification css */
.email-notify {
  border: 1px solid #d7d7d7;
  border-radius: 10px;
  height: 90%;
  padding-bottom: 10px;
}

.email-notify .email-heading {
  background: #244da7;
  padding: 13px 10px 5px 10px;
  border-radius: 10px 10px 0px 0px;
  color: #fff;
  text-align: center;
}

.email-notify .checkbox-section {
  padding: 16px;
}

.email-btn {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: 17px;
}

/* Localdispatch css */
.table-back {
  background: #fff;
  box-shadow: 1px 1px 10px #00000020;
  border-radius: 8px;
  position: relative;
}

.table-header {
  position: absolute;
  left: 12px;
  top: 10px;
  color: #244da1;
}

.table-header-section {
  background-color: #244da180;

}

.driver-details {
  background: #fff;
  box-shadow: 1px 1px 10px #00000020;
  border-radius: 6px;
  margin-bottom: 10px;
}

.detail-header {
  background: #244da1;
  padding: 10px 0px 10px 16px;
  border-radius: 6px 6px 0px 0px;
}

.detail-header p {
  margin-bottom: 0rem;
  color: #FFFFFF;
  font-weight: 500;
}

.pickup-info-header {
  background: #244da1;
  padding: 10px 0px 10px 16px;
  border-radius: 6px 6px 6px 6px;
}

.pickup-info-header p {
  margin-bottom: 0rem;
  color: #FFFFFF;
}

.delivery-info-header {
  background: #007204;
  padding: 10px 0px 10px 16px;
  border-radius: 6px 6px 6px 6px;
}

.delivery-info-header p {
  margin-bottom: 0rem;
  color: #FFFFFF;
}

.details-section {
  padding: 10px 16px 0px 16px;
}

.driver-mid-sec {
  display: flex;
  justify-content: space-between;
}

.driver-location Button {
  width: 150px;
  border-radius: 6px;
}

.img-section {
  display: flex;
  align-items: center;
}

.img-section .driver-img {
  background: #dfdfdf;
  padding: 10px;
  border-radius: 6px;
}

.img-section .driver-name {
  margin-left: 10px;
  font-weight: 500;
  text-transform: uppercase;
  color: #244da1
}

.selected-driver-details {
  display: flex;
  justify-content: space-between;
}

.driver-number {
  position: relative;
}

.driver-number h6 {
  font-size: 0.9rem;
}

.driver-number p {
  font-size: 0.9rem;
}

.driver-number:after {
  /* content: "|"; */
  position: absolute;
  right: -30px;
  top: 0;
  font-size: 24px;
  color: #787272;
}


.selected-driver-details .pcs {
  position: relative;
}

.selected-driver-details .pcs:before {
  /* content: "|"; */
  position: absolute;
  left: 60px;
  font-size: 24px;
  color: #787272;
}

.selected-driver-details .weight {
  position: relative;
}

.selected-driver-details .weight:before {
  /* content: "|"; */
  position: absolute;
  left: 60px;
  font-size: 24px;
  color: #787272;
}

.selected-row {
  color: red;
  font-weight: bold;
}

.add-driver-section {
  background: #fff;
  padding: 10px 10px 6px 10px;
  border-radius: 6px;
}

.pcs h6 {
  font-size: 0.9rem;
}

.pcs p {
  font-size: 0.9rem;
}

.weight p {
  font-size: 0.9rem;
}

.weight h6 {
  font-size: 0.9rem;
}

.AssignedDriver h6 {
  font-size: 0.9rem;
}

.AssignedDriver p {
  font-size: 0.9rem;
}

.add-driver-section p {
  margin-top: -21px;
  background: #fff;
  width: 59%;
  padding: 3px 15px;
  border: 1px solid #ededed;
  box-shadow: 1px 1px 10px #fff;
  border-radius: 6px;
  margin-bottom: 0px;
}

.routing-section {
  display: flex;
}

.driver-select {
  width: 100%;
  margin-right: 6px;
}

.routing-section select {
  box-shadow: none !important;
  height: 40px;
  border-radius: 4px !important;
}

.dispatch-main {
  margin-top: 15px
}

.now-later {
  padding: 9px 32px;
  border: 1px solid #dadada;
  border-radius: 6px;
  background: #fff;
  margin-top: 11px
}

.now-later .form-check-inline {
  margin-right: 2rem;
  margin-bottom: 0.063rem;
}

/* Order deatils css */
.order-details-section {
  background: #fff;
  padding: 16px;
  box-shadow: 1px 1px 10px #00000020;
  border-radius: 6px;
}

.basic-order-info {
  display: flex;
  background: #bf2527;
  padding: 8px 16px;
  border-radius: 6px;
  justify-content: space-between;
  color: #fff;
}

.edit-section {
  display: flex;
  justify-content: end;
  height: 40px;
  align-items: center;
  margin-bottom: 3px;
  margin-top: -13px;
}

.edit-order {
  background: green;
  padding: 5px;
  color: #fff;
  border-radius: 6px;
  margin-left: 20px;
}

.pickup-section {
  background: #244da180;
  padding: 10px;
  border-radius: 6px;
  position: relative;
}

.pickup-section h6 {
  position: absolute;
  top: -13px;
  left: 20px;
  background: #fff;
  padding: 7px 20px;
  color: #244da1;
  border-radius: 6px;
  border: 1px solid #244da1;
}

.pickup-info-section {
  background: #fff;
  border-radius: 6px;
}

.pickup-info-section p {
  background: #244da1;
  padding: 10px;
  border-radius: 6px 6px 0px 0px;
  margin-bottom: 6px;
  color: #fff;
}

.pick-info {
  padding: 0px 10px 1px 10px;
}

.dropup-section {
  background: #00720480;
  padding: 10px;
  border-radius: 6px;
  position: relative;
}

.dropup-section h6 {
  position: absolute;
  top: -13px;
  left: 20px;
  background: #fff;
  padding: 7px 20px;
  color: #007204;
  border-radius: 6px;
  border: 1px solid #007204;
}

.dropup-info-section {
  background: #fff;
  border-radius: 6px;
}

.dropup-info-section p {
  background: #007204;
  padding: 10px;
  border-radius: 6px 6px 0px 0px;
  margin-bottom: 6px;
  color: #fff;
}

.dropup-info {
  padding: 0px 10px 1px 10px;
}

.package-info-section {
  background: #244da180;
  margin-top: 6px;
  border-radius: 10px;
  margin-bottom: 6px;
}

.package-info-section p {
  background: #244da1;
  padding: 6px 16px;
  color: #fff;
  border-radius: 6px 6px 0px 0px;
  margin-bottom: 10px;
}

.package-section2 .flex-container {
  display: flex;
  justify-content: space-between;
}

.package-form {
  padding: 0px 16px 9px 16px;
}

.additional-section2 .flex-container {
  display: flex;
  justify-content: space-between;
}

.additional-info {
  background: #e5e5e5;
  margin-top: 6px;
  margin-bottom: 6px;
  border-radius: 6px;
}

.additional-info p {
  background: #244da1;
  padding: 6px 16px;
  color: #fff;
  border-radius: 6px 6px 0px 0px;
  margin-bottom: 10px;
}

.additional-info-form {
  padding: 0px 16px 1px 16px;
}

.additional-section2 {
  margin-bottom: 16px;
}

/* Truck list css */
.truck-list .flex-container {
  display: flex;
  flex-wrap: wrap;
  font-size: 30px;
  text-align: center;
}

.truck-list .flex-item {
  padding: 0px 10px 0px 0px;
  flex: 20% 1;
  border: 1px dotted #000;
  background: #fff;
  text-align: right;
  border-top: none;
}

.truck-img img {
  width: 90%;
  display: flex;
}

.truck-number h2 {
  margin-bottom: 0rem;
}

.truck-destination p {
  font-size: 16px;
  margin-bottom: 0rem;
}

.flex-item:nth-child(2) {
  border-left: none;
}

.flex-item:nth-child(3) {
  border-left: none;
}

.flex-item:nth-child(4) {
  border-left: none;
}

.flex-item:nth-child(5) {
  border-left: none;
}

.truck-list {
  background: #fff;
  border-radius: 6px;
}

.truck-list h6 {
  background: #244da180;
  padding: 10px;
  border-radius: 6px 6px 0px 0px;
  margin-bottom: 0px;
  color: #000;
}

/* Responsive layout - makes a one column-layout instead of a two-column layout */
@media (max-width: 800px) {
  .flex-item {
    flex: 50%;
  }
}

@media (max-width: 600px) {
  .flex-item {
    flex: 100%;
  }
}

/* Customer-list page css */
.customerlist {
  background: #fff;
  margin: -24px -10px -47px -22px;
}

.customerlist-leftsection {
  flex-grow: 1;

  height: 100%;
  background: #91a6d0;
  padding: 10px;
  box-shadow: inset 0 0 10px #244da1;
  /* max-width: 400px; */
  /* width: clamp(300px, 40%, 400px); */
  /* width: 21%; */
  /* position: fixed; */
}


.list-of-customer {
  height: 90vh;
  overflow: auto;
  position: relative;
  top: 10px;
}

.customer_list_section {
  background: #fff;
  display: flex;
  justify-content: space-between;
  border-radius: 6px;
  margin-bottom: 10px;
  margin-top: 3px;
  align-items: center;
  padding: 0px 10px;
  width: 100%;
}

.customer_list_section a {
  color: #b7b7b7;
  background: transparent;
  border: 1px;
}

.customer-list {
  display: flex;
  cursor: pointer;
  justify-content: space-between;
}

.account-number {
  border-right: 1px solid #000;
  padding-right: 25px;
  width: 80px;
}

.customer-name {
  position: relative;
  text-transform: uppercase;
  width: 100%;
  padding-left: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.customer-list-container {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(5, fr);
}


.sort_icon {
  display: flex;
}

.customer-search-form form {
  position: relative;
}

.customer-search-form input {
  height: 40px;
  background: #fff;
  border: none;
  border-radius: 4px;
  padding: 0px 20px 0px 45px;
}

.customer-search-form form span {
  color: #707070;
  position: absolute;
  top: 12px;
  left: 15px;
}

.view_count {
  position: relative;
}

div#lbsperlinear label {
  font-size: 14px;
}

.view_count .badge {
  position: absolute;
  top: -10px;
  right: -14px;
  padding: 7px 6px;
  border-radius: 50%;
  background-color: #244da1;
  color: white;
  min-width: 25px;
  min-height: 25px;
}

.sort_icon a {
  margin: 9px;
}

.button-section {
  display: flex;
  justify-content: end;
  z-index: 98;
}

.button-section button {
  position: relative;
  margin-right: 10px;
}

.icon-button {
  padding: 0px;
  font-size: 14px;
  white-space: nowrap;
  /* Prevent text from wrapping */
  overflow: hidden;
  /* Hide overflowing text */
  text-overflow: ellipsis;
  /* Display an ellipsis (...) for overflow */

}

button.icon-button-disable {
  color: #fff;
  background-color: gray;
  border: gray;
}

.mid-button {
  display: flex;
  border: 1px solid #244da1;
  padding: 0px 20px;
  border-radius: 6px;
  height: 55px;
  justify-content: space-around;
}

.delete-btn {
  background-color: #bf252780;
  width: 130px;
  padding-right: 12px;
  height: 38px;
  position: absolute;
}

.delete-btn:hover {
  background-color: #bf2527d9;
}

.delete-btn span {
  padding: 9px 9px 10px;
  background: #bf2527;
  border-radius: 0px 6px 6px 0px;
  position: relative;
  left: 25px;
  bottom: 0px;
}

.delete-btn.disabled,
.delete-btn:disabled {
  color: #fff;
  background-color: #bf252780;
  border-color: #bf252780;
}


.add-btn {
  /* background-color: #244da180; */
  background-color: #244da180;
  height: 38px;
  text-align: left;
  width: 150px;
}

.add-btn:hover {
  background-color: #244da1d9;
}

.add-btn span {
  padding: 6px 7px 9px 8px;
  background: #244da1;
  border-radius: 0px 6px 6px 0px;
  position: absolute;
  right: 0px;
  top: 0px;
}

.edit-btn {
  background-color: #266e2880;
  width: 125px;
  padding-right: 12px;
  height: 38px;
}

.edit-btn:hover {
  background-color: #266e28d9;
}

button.edit-btn.disable {
  background-color: #266e284d;
}

button.edit-btn.disable span {
  background: #266e286b;
}


.edit-btn span {
  padding: 10px 9px 10px;
  background: #266e28;
  margin-left: 39px;
  margin-right: -32px;
  border-radius: 0px 6px 6px 0px;
}

.show_detail .btn {
  background: #244da14d;
  display: flex;
  margin-left: auto;
  color: #244da1;
  transition: .2s ease-in-out;
  border-radius: 30px;
  box-shadow: 1px 1px 10px #00000026;
}

.customer-detail {
  padding: 24px 20px 24px 20px;
  width: 97%;
  margin-left: auto;
  margin-right: 0;
}

.last-order-update {
  display: flex;
  vertical-align: middle;
  height: 55px;
  align-items: end;
  color: #007204;
  font-weight: 600;
}

.shipper {
  background: #244da180;
  padding: 0px 20px 20px;
}

.shipper .label-heading {
  background: #244da1;
  color: #fff;
  border-radius: 6px;
}

.deliverto {
  background: #00720480;
  padding: 0px 20px 20px;
}

.deliverto .label-heading {
  background: #007204;
  color: #fff;
  border-radius: 6px;
}

.customer-detail h4 {
  color: #244da1;
  font-size: 1.5rem;
  text-transform: uppercase;
}

.checkbox-option {
  border: 1px solid #c2c2c2;
  padding: 28px 26px 16px 24px;
  border-radius: 6px;
}

.checkbox-option .form-check {
  padding-bottom: 12px;
}

.favorite.active {
  color: red;
}

a.view_count {
  color: #2d3e50;
}

.input-heading {
  display: flex;
  align-items: center;
  height: 58px;
}

.remote-form {
  background: #244da180;
  padding: 10px;
  margin: 10px 0px;
  border-radius: 6px;
}

.customer_list_section.active {
  background: #244da1;
  color: #fff;
}

.customer_list_section.active span.badge {
  background: #fff;
  color: #244da1;
}

.customer_list_section.active a.view_count {
  color: #fff;
}

.remote_login {
  background: #244da180;
  padding: 16px;
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 10px;
}

/* Accordion css */
.accordion-button {
  transition: none;
  background: #3c65bb;
  color: #fff;
  margin-bottom: 6px;
}

.accordion-button:not(.collapsed) {
  color: #fff;
  background-color: #244da1;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

.accordion-item {
  background-color: #f7f7f7;
  border: none;
}

.align_bottom {
  position: absolute;
  bottom: 0;
}

.range_label h6 {
  font-size: 0.75rem;
}

.range_label label {
  font-size: 0.8rem;
}

.timepicker .e-time-wrapper {
  height: 48px;
  border: 1px solid #cacaca !important;
  border-radius: 5px;
  padding: 10px;
  font-size: 15px !important;
  border-radius: 5px !important;
}

input#datepicker {
  height: 36px;
  font-size: 15px !important;
  width: 100%;
  border: 0;
}

.e-date-wrapper {
  padding: 10px;
}

input.e-input:before {
  background: transparent !important;
}

.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before {
  background: transparent !important;
}

.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after {
  background: transparent !important;
}

.form-bg {
  padding: 10px;
  background: #244da103;
  border-radius: 6px;
  margin: 10px 0px;
  border: 1px solid #244da13d;
}

button#name_address-tab-Customerdetails {
  background: #244da1;
  color: #fff;
  border-radius: 4px 6px 0px 0px;
  border: none;
}

/* table action btn */
.action_btn {
  display: flex;
}

.action_btn .edit_btn {
  color: #266e28;
  background: #266e2880;
  width: 33px;
  text-align: center;
  border-radius: 6px;
  box-shadow: 1px 1px 10px #0000001a;
  margin-right: 2px;
  cursor: pointer;
  height: 33px;
  line-height: 33px;
}

.edit_btn.disable {
  color: #266e2850;
}

.action_btn .del_btn {
  color: #bf2527;
  background: #bf252780;
  width: 33px;
  text-align: center;
  border-radius: 6px;
  box-shadow: 1px 1px 10px #0000001a;
  margin-left: 2px;
  cursor: pointer;
  height: 33px;
  line-height: 33px;
}

.del_btn.disable {
  background: #bf252750;
  color: #bf252780;
}

.btn-secondary.disable {
  background: #bf525780;
}

.save-btn.disable {
  background: #244da180;
}

button.add-btn.disable.btn.btn-primary {
  background-color: #485162d9;
}

button.add-btn.disable.btn.btn-primary .add-btn span {
  background: #2b3752;
}

.del_btn svg {
  font-size: 1.2rem;
}

.view_btn {
  color: #244da1;
  background: #244da180;
  width: 33px;
  text-align: center;
  border-radius: 6px;
  box-shadow: 1px 1px 10px #0000001a;
  margin-left: 2px;
  cursor: pointer;
  height: 33px;
  line-height: 33px;
}

.view_btn svg {
  font-size: 1.2rem;
}

/* section btn */
.section_action_btn {
  display: flex;
  justify-content: end;
}

.section_action_btn .save_btn .btn {
  background: #244da7;
  color: #fff;
  margin-right: 2px;
}

.section_action_btn .add_btn .btn {
  background: #bf2527;
  color: #fff;
  margin-left: 2px;
}

.add_btn.disable .btn {
  background: #bf252780;
}

.listing_section {
  position: relative;
}

.no-access-msg {
  margin-bottom: 0rem;
  text-align: center;
  color: #bf2527;
}

span.contactlist_icon {
  padding: 16px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 1px 1px 10px #00000030;
  position: absolute;
  z-index: 1;
  top: -26px;
  left: 10px;
}

.mulitple_contact {
  background: #244da166;
  padding: 32px 16px;
  border-radius: 6px;
}

.edit_btn_list {
  width: 50px;
  height: 58px;
  background: #266e2880;
  border-radius: 6px;
  line-height: 58px;
  margin-left: auto;
}

.del_btn_list {
  width: 50px;
  height: 58px;
  background: #bf252780;
  border-radius: 6px;
  line-height: 58px;
}

.contact_listing li {
  background: #91a6d0;
  border-radius: 6px 6px 0px 0px;
  margin-right: 10px;
  color: #244da1;
}

.contact_listing .nav-item.show .nav-link,
.contact_listing .nav-link.active {
  color: #fff;
  background-color: #244da1;
  border-color: #fff #fff #244da1;
  border-radius: 6px;
  border: 4px solid #244da1;
}

.contact_listing .nav-link {
  border: 5px solid transparent;
  color: #2d3e50;
  font-weight: 500;
}

.contact_listing .nav-link:focus,
.contact_listing .nav-link:hover {
  border-color: #fff #fff #244da1;
  isolation: isolate;
  color: #fff;
}

/* mulitselect button */
.multiSelectContainer .chip {
  background: #244da1;
}

.multiSelectContainer .highlightOption {
  background: #244da1;
  color: #fff;
}

.multiSelectContainer li:hover {
  background: #244da1;
}

.btn_section {
  display: flex;
  align-items: center;
  justify-content: end;
}

button.upload-btn {
  background: #244da1;
  border: none;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  float: right;
}

button.download-btn {
  background: #244da1;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  color: #fff;
  margin-right: 12px;
}

.btn-status .input-group-text {
  position: absolute;
  z-index: 0;
  height: 41px;
  width: 41px;
  padding: 13px;
  font-size: 20px;
  background: #244da133;
  color: #244da1;
  margin: 2px;
  top: -1px;
  left: -1px;
}

.btn-status label {
  padding-left: 60px;
  top: -1px;
  left: 1px;
}

.btn-status input {
  padding-left: 55px !important;
}

.cwt_form {
  background: #244da180;
  padding: 14px;
  border-radius: 10px;
}



.css-imf0nl-MuiDataGrid-root {
  padding: 10px;
  padding: 10px;
  border: 1px solid #fff !important;
  border-radius: 6px !important;
}

.MuiDataGrid-toolbarContainer {
  flex-direction: row-reverse;
}

.MuiDataGrid-columnHeaders {
  background: #244da1;
  color: #fff;
}

.css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root:hover {
  color: #fff;
}

.css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.css-imf0nl-MuiDataGrid-root {
  padding: 10px;
  padding: 10px;
  border: 1px solid #fff !important;
  border-radius: 6px !important;
}

.MuiDataGrid-toolbarContainer {
  flex-direction: row-reverse;
}

.MuiDataGrid-columnHeaders {
  background: #244da1;
  color: #fff;
}

.css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root:hover {
  color: #fff;
}

.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, .3) !important;
}

.MuiPaper-root {
  box-shadow: none !important;
}

/* upload data css */
.upload_data {
  display: flex;
  flex-direction: row;
}

.file-uploader-container {
  width: 75%;
  box-sizing: border-box;
  padding: 0.2em;
}

.file-uploader-input {
  width: 100%;
}

input#time {
  border-color: transparent;
  width: 100%;
  border: 0;
  padding-bottom: 7px;
  padding-left: 3px;
}

input#time1 {
  border-color: transparent;
  width: 100%;
  border: 0;
  padding-bottom: 7px;
  padding-left: 3px;
}

::focus-visible {
  border: none
}

/* Order Page css */
.accountid {
  position: relative;
}

.suggestion_box {
  position: absolute;
  width: 100%;
  z-index: 1;
  box-shadow: 1px 1px 10px #00000020;
}

.suggestion:hover {
  background-color: #e9e7e7;
}

.suggestion {
  cursor: pointer;
  background: #ededed;
  padding: 4px 15px;
  border: 1px solid #e3e3e3;
}

p.populate-account {
  margin-left: 10px;
  color: #244da1;
  font-weight: 500;
  font-size: 17px;
}

.populate-account {
  margin-right: 6px;
  max-width: 50%;
  min-width: 150px;
  height: auto;
  float: left;
  clear: left;
  /* white-space: nowrap; Prevent text from wrapping */
  /* overflow: hidden; /* Hide overflowing text */
  /* text-overflow: ellipsis; Display an ellipsis (...) for overflow */

}

.populate-section {
  display: flex;
  justify-content: flex-end;
}

p.populate-account:hover {
  color: #1f3f83;
}

.swal-text {
  text-align: center;
  color: #000;
  line-height: 2;
  font-weight: 500;
}

/* Multiple email quote css */
.tags-input {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 48px;
  /* width: 30%; */
  padding: 0 8px;
  border: 1px solid rgb(214, 216, 218);
  border-radius: 6px;
  /* margin-left: 5px; */
  margin-bottom: 13px;
}

.contain-tags {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 8px 0 0 0;

}

#tags {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 8px 0 0 0;
}

.tag {
  width: auto;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 0 8px;
  font-size: 14px;
  list-style: none;
  border-radius: 6px;
  margin: 0 8px 8px 0;
  background: #0052cc;
}

.tag .tag-title {
  margin-top: 3px;
}

.tag .tag-close-icon {
  display: block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  font-size: 14px;
  margin-left: 8px;
  color: #0052cc;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
}

.email-input {
  flex: 1;
  border: none;
  height: 46px;
  font-size: 14px;
  padding: 10px;
  width: 33%;
}

.emailphonecontain {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 10px;
  width: 100%;
}

.email-input:focus {
  outline: transparent;
}

/* Role Management section css */
/* .role-management {
  background: #fff;
  margin: -19px -10px -47px -22px;
} */

.funtional-role-section {
  background: #8DABE9;
  padding: 24px;
  height: 91vh;
  position: fixed;
  min-width: 21%;
  overflow-y: scroll;
}

.role-assignment {
  display: flex;
  justify-content: space-between;
}

.add-role-form {
  background: #fff;
  padding: 16px;
  border-radius: 6px;
  border: 1px solid #e7e7e7;
  margin-top: -15px;
}

.role-details-section {
  padding: 20px 10px 20px 0px;
}

.permission-icon {
  background: #e3e1e1;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  margin-right: 36px;
}

.permission-icon.active {
  background: rgb(36 77 161 / 50%);
}

.permission-icon.active svg {
  fill: #fff;
}

.role-permission {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dbd6d6;
  padding: 10px 10px;
}

.permission-name {
  display: grid;
}

span.permission-name {
  font-weight: 500;
  color: #434D71;
}

span.permission-name-details {
  font-size: 14px;
  color: #8b8b8b;
}

.permission-role-selection .form-check .form-check-input {
  width: 1.5rem;
  height: 1.5rem;
}

.permission-role-selection input[type="date" i] {
  width: 208px;
}

ul.liststyle {
  padding-left: 0rem;
}

ul.liststyle li {
  list-style-type: none;
  padding: 5px 0px;
}

ul.liststyle li .MuiSvgIcon-root {
  font-size: 1rem;
  margin-right: 10px;
  fill: #3c65bb;
}

.assign-role {
  border-radius: 6px;
}

.created-role {
  display: flex;
  justify-content: space-between;
  background: #8DABE9;
  box-shadow: 1px 1px 10px #00000020;
  padding: 10px;
  width: 96%;
  margin-left: 10px;
  margin-top: 10px;
}

.created-role {
  display: flex;
  justify-content: space-between;
  background: #cfdbf3;
  box-shadow: 1px 1px 10px #00000020;
  padding: 10px;
  width: 96%;
  margin-left: 10px;
  margin-top: 10px;
  align-items: baseline;
  border-radius: 6px;
  font-weight: 500;
}

.created-role .delete-role .MuiSvgIcon-root {
  fill: #bf2527;
}

.user-role-table {
  height: 100vh;
  position: relative;
  margin-top: 10px;
  box-shadow: 1px 1px 10px #00000020;
  border-radius: 6px;
  padding: 15px 0px;
}

.user-role-table h6 {
  position: absolute;
  margin-left: 14px;
  margin-top: 8px;
}

.user-role-management {
  background: #fff;
  margin: -18px -10px 0px -14px;
  padding: 12px;
}

.add-user-profile {
  background: #fff;
  padding: 20px;
  border-radius: 6px;
  margin: -10px 0px 10px 0px;
  border: 1px solid #e9e9e9;
}

.add-user-profile .MuiSvgIcon-root {
  margin-right: 6px;
}

.add-user-profile .btn {
  display: flex;
  align-items: center;
  width: 169px;
  justify-content: center;
}

.user-role-form {
  box-shadow: 1px 1px 10px #00000020;
  border: none;
  position: relative;
}

.user-role-form .label-heading {
  border: 1px solid #f6f6f6;
}

.user-funtional-role {
  border: 1px solid #e9e9e9;
  padding: 20px 10px;
  border-radius: 6px;
}

.user-funtional-role .role-assignment {
  border-bottom: 1px solid #c9c9c9;
  padding: 0px 0px 10px 0px;
}

.change-role {
  display: flex;
  align-items: center;
}

.change-role p {
  margin-bottom: 0px;
}

.role-selection {
  padding: 0px 20px;
}

.change-role-btn {
  background: #bf2527;
  padding: 11px;
  border-radius: 6px;
}

.change-role-btn button {
  background: transparent;
  border: none
}

.change-role-btn svg {
  fill: #fff;
}

.add-role-button-btn span {
  background: #244da1;
  padding: 14px 24px;
  border-radius: 6px;
  color: #fff;
  position: relative;
  top: 12px;
  right: -55px;
}

.account-list label {
  color: #000;
  font-size: 16px;
  position: absolute;
  z-index: 2;
  left: 8px;
  top: 11px;
  padding: 0 2px;
  pointer-events: none;
  transition: transform 100ms ease;
}

/* .account-list:active label {
  top: 3px;
  left: 14px;
  font-size: 14px;
  color: #6f6f6f;
  opacity: .65;
  transform: scale(.85) translateY(-0.5rem) translateX(0.15rem);
} */
.account-list .cRgVbc>input {
  width: 100%;
  padding: 14px 0 0px 11px;
}

.form-floating>.form-control11:focus~label,
.form-floating>.form-control11:not(:placeholder-shown)~label,
.form-floating>.form-select~label {
  opacity: .65;
  transform: scale(.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating.search {
  position: absolute;
  right: 400px;
  width: 20%;
  margin-top: -8px;
}

.select-option.form-floating {
  position: absolute;
  right: 645px;
  width: 20%;
  margin-top: -8px;
  z-index: 9
}

.user-permission .tab-content {
  width: 66%;
  position: absolute;
  top: 71px;
  right: 13px;
}

.user-permission button {
  background: #fff0 !important;
  border: none !important;
  padding: 10px 0px;
  font-weight: 500 !important;
  text-align: left;
}

.user-permission button::before {
  content: '>>';
  margin-right: 10px;
}

.user-permission ul.nav.nav-tabs {
  display: block;
  border-bottom: 0px;
}

/* Right sidebar for user role */

.modal.left .modal-dialog,
.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 1100px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content,
.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal.left .modal-body,
.modal.right .modal-body {
  padding: 15px 15px 80px;
}


/*Right*/
.modal.right.fade .modal-dialog {
  right: 0px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.in .modal-dialog {
  right: 0;
}

/* Vehicle Module  */
.image-upload {
  width: 100px;
  height: 80px;
  border: 1px solid #e3e3e3;
  line-height: 80px;
  text-align: center;
  border-radius: 6px;
}

.image-upload svg {
  font-size: 3rem;
}

.dynamic-input .form-floating {
  margin-right: 10px;
}

/* Employee module css */

.drop_box {
  margin: 10px 0;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 3px dotted #a3a3a3;
  border-radius: 5px;
}

.drop_box h4 {
  font-size: 16px;
  font-weight: 400;
  color: #2e2e2e;
}

.drop_box p {
  margin-top: 6px;
  margin-bottom: 6px;
  font-size: 12px;
  color: #a3a3a3;
}

.drop_box svg {
  font-size: 3rem;
}

.drop_box .btn {
  text-decoration: none;
  background-color: #244da1;
  color: #ffffff;
  padding: 10px 20px;
  margin-top: -23px;
}

.drop_box input {
  position: relative;
  top: 15px;
  left: 66px;
  opacity: 0;
}

.drop_box .btn:hover {
  text-decoration: none;
  background-color: #ffffff;
  color: #244da1;
  font-weight: 500;
  border: 1px solid #244da1;
}

.drop_box .form input {
  margin: 10px 0;
  width: 100%;
  background-color: #e2e2e2;
  border: none;
  outline: none;
  padding: 12px 20px;
  border-radius: 4px;
}

.document-list {
  display: flex;
  justify-content: space-between;
  background: #3c65bb80;
  padding: 10px;
  border-radius: 6px;
}

.document-name {
  color: #244da1;
  display: flex;
}

.document-name p {
  color: #244da1;
  margin-bottom: 0px;
  margin-top: 2px;
  margin-left: 10px;
  font-weight: 500;
}

.document-list svg {
  fill: #244da1;
}

.image-upload-section {
  position: relative;
}

.image-upload-section .image-upload-btn {
  position: absolute;
  bottom: 0px;
  width: 240px;
  cursor: pointer;
  opacity: 0;
  height: 72px;
}

.employee-image-upload {
  width: 100%;
  height: 200px;
  line-height: 200px;
  border: 1px solid #e3e3e3;
  text-align: center;
  border-radius: 6px 6px 0px 0px;
}

.employee-image-upload img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.employee-image-upload p {
  text-align: center;
}

.employee-image-upload svg {
  width: 3em;
  height: 3em;
}

.image-upload-section .upload-btn {
  background: #3c65bb;
  padding: 12px;
  text-align: center;
  border-radius: 0px 0px 6px 6px;
  color: #fff;
}

.image-upload-section .upload-btn p {
  margin-bottom: 0px;
}

.multi-certification-add {
  display: flex;
  justify-content: space-between;
}

.certification-section h6 {
  margin-right: 24px;
}

.status-btn {
  height: 48px;
  background: #fff;
  border-radius: 4px;
  padding: 6px 16px;
}

.form-check.form-switch.status-toggle input#flexSwitchCheckDefault {
  float: right;
}

.form-check.form-switch.status-toggle {
  padding-left: 0px;
  padding-top: 8px;
}

.save-btn .btn {
  width: 8%;
  position: absolute;
  right: 20px
}

.active1 {
  color: red
}

.dispatchItems {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.longHaulTrip {
  /* line-height: 10px; */
  align-items: center;
}

.dispatch {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dispatchItems input {
  font-size: 16px;
  width: 240px;
}

.dispatchItemsButton {
  flex: 0.8;
  display: flex;
  align-items: center;
}

.createButton {
  display: flex;
  justify-content: space-between;
  flex: 1;
  align-items: center;
}

.form.nosubmit {
  border: none;
  padding: 0;
}

.btn {
  font-size: 15px
}

.activeTripBox {
  display: flex;
  justify-content: space-between;
}

.tripBox span {
  display: block;
  color: #244da1;
}

.tripBox label {
  font-size: 10px;
  margin-left: 5px;
  color: gray;
}

.tripBox h6 {
  font-size: 15px;
  margin-left: 5px;
}

.tripBoxStatus label {
  margin-right: 15px;
  font-size: 10px;
  color: gray;
}

.tripBoxStatus span {
  display: block;
  color: green
}

.activeContainer {
  height: auto;
  width: 160px;
  border: 1px solid rgb(194, 189, 189);
}

.description label {
  font-size: 10px;
  margin-left: 5px;
  color: gray;
}

.description h6 {
  font-size: 13px;
  margin-left: 5px;
  color: #20206e;
}

.descriptionAssigned label {
  font-size: 9px;
  margin-right: 5px;
  color: gray;
}

.descriptionAssigned span {
  display: block;
  font-size: 13px;
  color: #20206e;
}

.descriptionAssigned {
  margin-left: 10px
}

.mainContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.driverAddButton {
  background-color: blue;
  border-radius: 5px;
  width: 100px;
}

.driver-container {
  display: flex;
  justify-content: space-between;
  margin-top: -20px;
}

.driverList-container {
  height: 40px;
  color: #FFFFFF;
  background-color: #244da1;
  border-radius: 6px 6px 0px 0px;
  border-color: #FFFFFF00;
}

.image-menu img {
  height: 52px;
  width: 60px;
}

.input-menu {
  text-align: right;
  padding-right: 10px;
}

.input-menu input {
  width: 10px;
  height: 20px;
  /* margin-left: 40px; */
}

.input-menu input[type=text] {
  border-radius: 6px;
  border: 0.1rem solid gray;
  width: 80px;
  margin-left: 10px;
}

.dropDriver {
  min-width: 0;
}

.dropdown-toggle::after {
  content: none;
}

.driverList-Items {
  display: flex;
  justify-content: space-between;
}

.driverList-menu {
  display: flex;
  flex-wrap: wrap;
  /* overflow-y: scroll; */
  height: 100%;
  max-height: 90vh;
  background-color: #FFFFFF;

}

.main-container {
  background-color: #FFFFFF00;
  container-type: inline-size;
}

@container (max-width: 244px) {
  .driverList-container {
    width: 487px;
  }

  .driver-details {
    width: 487px;
  }

  .driverList-menu {
    width: 487px;
  }
}

@container (min-width: 244px) and (max-width: 366px) {
  .driverList-container {
    width: 487px;
  }

  .driver-details {
    width: 487px;
  }

  .driverList-menu {
    width: 487px;
  }
}

@container (min-width: 366px) {
  .driverList-container {
    width: 487px;
  }

  .driver-details {
    width: 487px;
  }

  .driverList-menu {
    width: 487px;
  }
}

@container (min-width: 486px) {
  .driverList-container {
    width: 487px;
  }

  .driver-details {
    width: 487px;
  }

  .driverList-menu {
    width: 487px;
  }
}

@container (min-width: 608px) {
  .driverList-container {
    width: 608px;
  }

  .driver-details {
    width: 608px;
  }

  .driverList-menu {
    width: 608px;
  }
}

@container (min-width: 729px) {
  .driverList-container {
    width: 730px;
  }

  .driver-details {
    width: 730px;
  }

  .driverList-menu {
    width: 730px;
  }
}

@container (min-width: 851px) {
  .driverList-container {
    width: 852px;
  }

  .driver-details {
    width: 852px;
  }

  .driverList-menu {
    width: 852px;
  }
}

.contain-order-flex {
  display: flex;
}

.left-flex {
  flex: 1;
}

.driverList-submenu {
  border: 1px dotted;
  width: 8.02rem
}

.input-menu span {
  display: inline;
  font-size: 13px;
  margin-left: 15px;
}

.input-menu label {
  color: #244da1;
  margin-left: 5px;
}

.radioButton {
  display: flex;
  padding: 10px 0px;
}

.multiple-radios {
  display: flex;
  padding: 10px;
  border: 1px solid #e2dfdf;
  border: 1px solid gray;
  width: max-content;
  margin-top: 9px;
  margin-right: 40px;
  margin-left: 20px;
  border: 1px solid gray;
  width: max-content;
  margin-top: 9px;
  margin-right: 40px;
  margin-left: 20px;
  border-radius: 6px;
  margin-right: 10px;
  justify-content: space-between;
}

.account-EDI {
  margin-left: 200px;
}

.account-table {
  height: 100vh;
  box-shadow: inset 0 0 10px #244da1;
  width: 28.5%;
  position: fixed;
}

.invoice-table {
  position: relative;
  margin-top: 10px;
  border-radius: 6px;
  padding: 10px;
  background: #244da136;
}

.invoice-table h6 {
  position: absolute;
  left: 28px;
  top: 23px;
  z-index: 1;
}

.invoice-table .MuiDataGrid-root {
  background: #fff;
  padding: 24px 0px;
}

.add-role-button {
  margin-right: 10px;
  justify-content: end;
}

.show-notes {
  position: absolute;
  width: 25%;
  z-index: 1;
  right: 22px;
  top: 150px;
  box-shadow: 1px 1px 10px #00000020;
  border: 1px solid #e9e9e9;
  background: #fff;
  border-radius: 6px;
}

.show-notes .form-floating {
  padding: 10px;
}

.show-notes .form-floating>label {
  padding: 21px;
}

.comment-list {
  border-top: 1px solid #e7e7e7;
}

.comment-list p {
  margin-bottom: 5px;
  font-weight: 500;
  color: #244da1c9;
}

.date-time {
  display: flex;
  justify-content: space-between;
  padding: 0px 16px 0px 0px;
}

.view-more {
  text-align: center;
  color: #244da1;
  font-weight: 500;
}

.multi-email-box {
  width: 100%;
  height: 200px;
  border: 1px solid #d3d3d3;
  border-radius: 6px;
  margin: 10px 0px;
}


ul.listStyle li a:hover,
ul.listStyle li a:active {
  color: #39aea8;
  text-decoration: none;
}

.admin-buttons {
  display: flex;
  margin-top: 20px;
  justify-content: end;
}

.admin-buttons span {
  margin: 0px 25px 0px 0px;
}

.edit_Button {
  height: auto;
}

.boxHover {
  width: 150px;
  padding: 0px 8px 0px 25px;
  border: 1px solid transparent;
  display: flex;
  justify-content: space-between;
}

.boxHoverNew {
  width: 225px;
}

/* .boxHoverNew:hover {
  font-size: 14px;
  padding: 0px 8px 0px 25px;
  border: 1px solid #ccc;
  border-radius: 5px;
} */
/* 
.boxHoverNew .cross {
  display: none;
} */

.boxHoverNew:hover .cross {
  display: inline-block;
}

.cross {
  margin-left: 8px;
}

.boxHover label {
  font-size: 15px;
}

.discriptionBox {
  display: flex;
  flex-wrap: wrap;
}

.addVehicle {
  display: flex;

}

.addSpecifications {
  margin-right: 45px;
  width: 200px;
}

.img-vehicle {
  height: 100px !important;
  line-height: 90px !important;
}

.img-vehicle img {
  width: 100%;
  height: 100px;
}

.inventory .upload-btn {
  padding: 5px !important;
}




/* AR module */
.select-account {
  box-shadow: 1px 1px 10px #00000020;
  border-radius: 6px;
  padding: 15px;
}

.payment-details {
  box-shadow: 1px 1px 10px #00000020;
  border-radius: 6px;
  padding: 15px;
}

.sales-data {
  box-shadow: 1px 1px 10px #00000020;
  border-radius: 6px;
  padding: 15px;
}

.total {
  display: flex;
}

.total-text {
  width: 100%;
  background: #bf252785;
  height: 48px;
  line-height: 48px;
  border-radius: 4px 0px 0px 4px;
  font-size: 17px;
  color: #a70e10;
  text-align: center;
}

.generate-report {
  width: 14%;
  display: flex;
  float: right;
}

.ageed-section {
  box-shadow: 1px 1px 10px #00000020;
  border-radius: 6px;
  padding: 15px;
}

.deposit-section {
  display: flex;
  justify-content: space-between;
}

.invoice-date {
  display: flex;
}

.print-invoice {
  display: flex;
  justify-content: space-between;
}

.invoice-btn .btn {
  height: 48px;
  margin-left: 10px;
}

.invoice-date .to {
  display: flex;
  align-items: center;
  margin-right: 10px;
  font-weight: 500;
}

.invoice-btn {
  margin-right: 10px;
}

/* default pricing css */
.default-pricing {
  padding: 24px 20px 24px 20px;
  background: white;
  box-shadow: 1px 1px 10px #958f8f8f;
  border-radius: 6px;
  margin-left: auto;
  margin-right: 0;
  width: 99%;
}

.default-pricing-title {
  display: flex;
  justify-content: space-between;
}

.default-pricing-title h5 {
  margin-left: 30px;
}

.default-pricing-title .default-pricing-btn {
  margin-right: 30px;
}

.tripButtons {
  margin-left: 40px;
  background: #244da180;
  color: #244da1;
  border-radius: 5px;
  padding: 3px;
}

.containerForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

.activenew {
  background: white;
}

.open-order-table {
  height: 100vh;
  position: relative;
  margin-top: 10px;
  box-shadow: 1px 1px 10px #00000020;
  border-radius: 6px;
  padding: 15px 0px;
}

.open-order-table h6 {
  position: absolute;
  margin-left: 14px;
  margin-top: 8px;
}

.createNewTrip {
  background: #244da1;
  /* align-items: center; */
}

.createNewTrip h6 {
  color: white;
  /* align-items: center; */
}

.trailer-container {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  gap: 10px 10px;
}

.create-form-container {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  gap: 10px 10px;

}

.create-form-container .form-floating label {
  padding: 0 15px;
  line-height: 38px;
}

.create-form-container .form-floating input {
  height: 38px;
}

.create-form-date {
  width: 65px;
}

.estimated-border-field {
  justify-content: space-between;
}

.routingDriver {
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  /* Prevent text from wrapping */
  overflow: hidden;
  /* Hide overflowing text */
  text-overflow: ellipsis;
  /* Display an ellipsis (...) for overflow */

}

.activeBoardSelect {
  width: 300px;
  margin-right: 10px;
}

.fieldset-routing .routingDriver {
  margin-top: -24px;
}

.vehicleButtonsInventory .addVehicleButton {
  margin-right: 20px
}

.statusUpdate {
  color: green;
  margin-left: 5px;
}

.dispatchButtons {
  display: flex;
  justify-content: space-between;
}

.ManageInventoryFields {
  display: flex;
  justify-content: space-between;
}

.subBtnInventory {
  width: 120px;
}

.manageInventoryTable {
  height: 800px;
}

p.data-not-found {
  text-align: center;
  margin-top: 24px;
}

.addVehicleTable {
  height: 800px;
}

/* .btn-distance .btn-yard .btn-future .btn-print .btn-prices .btn-access{
  width: 100px;
} */
.btn-prices {
  width: 100px;
}

.btn-future {
  width: 100px;
}

.btn-yard {
  width: 100px;
}

.btn-access {
  width: 100px;
}

.btn-print {
  width: 100px;
}

.btn-distance {
  width: 100px;
}

.btn-Maps {
  width: 100px;
  padding: 0px 5px 0px 0px;
  ;
}

.btn-Maps:hover {
  background-color: green;
}

.rate-button {
  display: flex;
  justify-content: space-between;
}

.newtons-cradle {
  --uib-size: 50px;
  --uib-speed: 1.2s;
  --uib-color: #474554;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--uib-size);
  height: var(--uib-size);
  margin-left: auto;
  margin-right: auto;
}

.newtons-cradle__dot {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  width: 25%;
  transform-origin: center top;
}

.newtons-cradle__dot::after {
  content: '';
  display: block;
  width: 100%;
  height: 25%;
  border-radius: 50%;
  background-color: var(--uib-color);
}

.newtons-cradle__dot:first-child {
  animation: swing var(--uib-speed) linear infinite;
}

.newtons-cradle__dot:last-child {
  animation: swing2 var(--uib-speed) linear infinite;
}

@keyframes swing {
  0% {
    transform: rotate(0deg);
    animation-timing-function: ease-out;
  }

  25% {
    transform: rotate(70deg);
    animation-timing-function: ease-in;
  }

  50% {
    transform: rotate(0deg);
    animation-timing-function: linear;
  }
}

@keyframes swing2 {
  0% {
    transform: rotate(0deg);
    animation-timing-function: linear;
  }

  50% {
    transform: rotate(0deg);
    animation-timing-function: ease-out;
  }

  75% {
    transform: rotate(-70deg);
    animation-timing-function: ease-in;
  }
}

.banter-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 72px;
  height: 72px;
  margin-left: -36px;
  margin-top: -36px;
}

.banter-loader__box {
  float: left;
  position: relative;
  width: 20px;
  height: 20px;
  margin-right: 6px;
}

.banter-loader__box:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;
}

.banter-loader__box:nth-child(3n) {
  margin-right: 0;
  margin-bottom: 6px;
}

.banter-loader__box:nth-child(1):before,
.banter-loader__box:nth-child(4):before {
  margin-left: 26px;
}

.banter-loader__box:nth-child(3):before {
  margin-top: 52px;
}

.banter-loader__box:last-child {
  margin-bottom: 0;
}

@keyframes moveBox-1 {
  9.0909090909% {
    transform: translate(-26px, 0);
  }

  18.1818181818% {
    transform: translate(0px, 0);
  }

  27.2727272727% {
    transform: translate(0px, 0);
  }

  36.3636363636% {
    transform: translate(26px, 0);
  }

  45.4545454545% {
    transform: translate(26px, 26px);
  }

  54.5454545455% {
    transform: translate(26px, 26px);
  }

  63.6363636364% {
    transform: translate(26px, 26px);
  }

  72.7272727273% {
    transform: translate(26px, 0px);
  }

  81.8181818182% {
    transform: translate(0px, 0px);
  }

  90.9090909091% {
    transform: translate(-26px, 0px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

.banter-loader__box:nth-child(1) {
  animation: moveBox-1 4s infinite;
}

@keyframes moveBox-2 {
  9.0909090909% {
    transform: translate(0, 0);
  }

  18.1818181818% {
    transform: translate(26px, 0);
  }

  27.2727272727% {
    transform: translate(0px, 0);
  }

  36.3636363636% {
    transform: translate(26px, 0);
  }

  45.4545454545% {
    transform: translate(26px, 26px);
  }

  54.5454545455% {
    transform: translate(26px, 26px);
  }

  63.6363636364% {
    transform: translate(26px, 26px);
  }

  72.7272727273% {
    transform: translate(26px, 26px);
  }

  81.8181818182% {
    transform: translate(0px, 26px);
  }

  90.9090909091% {
    transform: translate(0px, 26px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

.banter-loader__box:nth-child(2) {
  animation: moveBox-2 4s infinite;
}

@keyframes moveBox-3 {
  9.0909090909% {
    transform: translate(-26px, 0);
  }

  18.1818181818% {
    transform: translate(-26px, 0);
  }

  27.2727272727% {
    transform: translate(0px, 0);
  }

  36.3636363636% {
    transform: translate(-26px, 0);
  }

  45.4545454545% {
    transform: translate(-26px, 0);
  }

  54.5454545455% {
    transform: translate(-26px, 0);
  }

  63.6363636364% {
    transform: translate(-26px, 0);
  }

  72.7272727273% {
    transform: translate(-26px, 0);
  }

  81.8181818182% {
    transform: translate(-26px, -26px);
  }

  90.9090909091% {
    transform: translate(0px, -26px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

.banter-loader__box:nth-child(3) {
  animation: moveBox-3 4s infinite;
}

@keyframes moveBox-4 {
  9.0909090909% {
    transform: translate(-26px, 0);
  }

  18.1818181818% {
    transform: translate(-26px, 0);
  }

  27.2727272727% {
    transform: translate(-26px, -26px);
  }

  36.3636363636% {
    transform: translate(0px, -26px);
  }

  45.4545454545% {
    transform: translate(0px, 0px);
  }

  54.5454545455% {
    transform: translate(0px, -26px);
  }

  63.6363636364% {
    transform: translate(0px, -26px);
  }

  72.7272727273% {
    transform: translate(0px, -26px);
  }

  81.8181818182% {
    transform: translate(-26px, -26px);
  }

  90.9090909091% {
    transform: translate(-26px, 0px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

.banter-loader__box:nth-child(4) {
  animation: moveBox-4 4s infinite;
}

@keyframes moveBox-5 {
  9.0909090909% {
    transform: translate(0, 0);
  }

  18.1818181818% {
    transform: translate(0, 0);
  }

  27.2727272727% {
    transform: translate(0, 0);
  }

  36.3636363636% {
    transform: translate(26px, 0);
  }

  45.4545454545% {
    transform: translate(26px, 0);
  }

  54.5454545455% {
    transform: translate(26px, 0);
  }

  63.6363636364% {
    transform: translate(26px, 0);
  }

  72.7272727273% {
    transform: translate(26px, 0);
  }

  81.8181818182% {
    transform: translate(26px, -26px);
  }

  90.9090909091% {
    transform: translate(0px, -26px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

.banter-loader__box:nth-child(5) {
  animation: moveBox-5 4s infinite;
}

@keyframes moveBox-6 {
  9.0909090909% {
    transform: translate(0, 0);
  }

  18.1818181818% {
    transform: translate(-26px, 0);
  }

  27.2727272727% {
    transform: translate(-26px, 0);
  }

  36.3636363636% {
    transform: translate(0px, 0);
  }

  45.4545454545% {
    transform: translate(0px, 0);
  }

  54.5454545455% {
    transform: translate(0px, 0);
  }

  63.6363636364% {
    transform: translate(0px, 0);
  }

  72.7272727273% {
    transform: translate(0px, 26px);
  }

  81.8181818182% {
    transform: translate(-26px, 26px);
  }

  90.9090909091% {
    transform: translate(-26px, 0px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

.banter-loader__box:nth-child(6) {
  animation: moveBox-6 4s infinite;
}

@keyframes moveBox-7 {
  9.0909090909% {
    transform: translate(26px, 0);
  }

  18.1818181818% {
    transform: translate(26px, 0);
  }

  27.2727272727% {
    transform: translate(26px, 0);
  }

  36.3636363636% {
    transform: translate(0px, 0);
  }

  45.4545454545% {
    transform: translate(0px, -26px);
  }

  54.5454545455% {
    transform: translate(26px, -26px);
  }

  63.6363636364% {
    transform: translate(0px, -26px);
  }

  72.7272727273% {
    transform: translate(0px, -26px);
  }

  81.8181818182% {
    transform: translate(0px, 0px);
  }

  90.9090909091% {
    transform: translate(26px, 0px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

.banter-loader__box:nth-child(7) {
  animation: moveBox-7 4s infinite;
}

@keyframes moveBox-8 {
  9.0909090909% {
    transform: translate(0, 0);
  }

  18.1818181818% {
    transform: translate(-26px, 0);
  }

  27.2727272727% {
    transform: translate(-26px, -26px);
  }

  36.3636363636% {
    transform: translate(0px, -26px);
  }

  45.4545454545% {
    transform: translate(0px, -26px);
  }

  54.5454545455% {
    transform: translate(0px, -26px);
  }

  63.6363636364% {
    transform: translate(0px, -26px);
  }

  72.7272727273% {
    transform: translate(0px, -26px);
  }

  81.8181818182% {
    transform: translate(26px, -26px);
  }

  90.9090909091% {
    transform: translate(26px, 0px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

.banter-loader__box:nth-child(8) {
  animation: moveBox-8 4s infinite;
}

@keyframes moveBox-9 {
  9.0909090909% {
    transform: translate(-26px, 0);
  }

  18.1818181818% {
    transform: translate(-26px, 0);
  }

  27.2727272727% {
    transform: translate(0px, 0);
  }

  36.3636363636% {
    transform: translate(-26px, 0);
  }

  45.4545454545% {
    transform: translate(0px, 0);
  }

  54.5454545455% {
    transform: translate(0px, 0);
  }

  63.6363636364% {
    transform: translate(-26px, 0);
  }

  72.7272727273% {
    transform: translate(-26px, 0);
  }

  81.8181818182% {
    transform: translate(-52px, 0);
  }

  90.9090909091% {
    transform: translate(-26px, 0);
  }

  100% {
    transform: translate(0px, 0);
  }
}

.banter-loader__box:nth-child(9) {
  animation: moveBox-9 4s infinite;
}

.spinner {
  --size: 20px;
  --first-block-clr: #005bba;
  --second-block-clr: #bf2527;
  --clr: #111;
  width: 100px;
  height: 100px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.spinner::after,
.spinner::before {
  box-sizing: border-box;
  position: absolute;
  content: "";
  width: var(--size);
  height: var(--size);
  top: 50%;
  animation: up 2.4s cubic-bezier(0, 0, 0.24, 1.21) infinite;
  left: 50%;
  background: var(--first-block-clr);
}

.spinner::after {
  background: var(--second-block-clr);
  top: calc(50% - var(--size));
  left: calc(50% - var(--size));
  animation: down 2.4s cubic-bezier(0, 0, 0.24, 1.21) infinite;
}

@keyframes down {

  0%,
  100% {
    transform: none;
  }

  25% {
    transform: translateX(100%);
  }

  50% {
    transform: translateX(100%) translateY(100%);
  }

  75% {
    transform: translateY(100%);
  }
}

@keyframes up {

  0%,
  100% {
    transform: none;
  }

  25% {
    transform: translateX(-100%);
  }

  50% {
    transform: translateX(-100%) translateY(-100%);
  }

  75% {
    transform: translateY(-100%);
  }
}

.burger {
  position: relative;
  width: 40px;
  height: 30px;
  background: transparent;
  cursor: pointer;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.burger input {
  display: none;
}

.burger span {
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  background: black;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

.burger span:nth-of-type(1) {
  top: 0px;
  transform-origin: left center;
}

.burger span:nth-of-type(2) {
  top: 50%;
  transform: translateY(-50%);
  transform-origin: left center;
}

.burger span:nth-of-type(3) {
  top: 100%;
  transform-origin: left center;
  transform: translateY(-100%);
}

.burger input:checked~span:nth-of-type(1) {
  transform: rotate(45deg);
  top: 0px;
  left: 5px;
}

.burger input:checked~span:nth-of-type(2) {
  width: 0%;
  opacity: 0;
}

.burger input:checked~span:nth-of-type(3) {
  transform: rotate(-45deg);
  top: 28px;
  left: 5px;
}

.btn-backtoorder {
  height: auto;
}

.province-selector {
  height: 100px;
}


.sidebar {
  transition: transform 0.3s ease-in-out;
  transform: translateX(-100%);
  width: 30%;
  position: absolute;
  z-index: 100;
  height: 100
}

.sidebar.open {
  transform: translateX(0);
}

.customer-button {
  position: absolute;
  padding: 10px;
  border-radius: 0x 6px 6px 0px;
  background: #244da1;
  color: #fff;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  z-index: 101;
  width: 2.5%;

  height: 100%;
  transform: translateX(0);
  margin: 0;
}

.customer-button.open {
  transform: translateX(1200%);
  background: #5b8af0;
}


.overlay-shadow {
  background-color: rgba(0, 0, 0);
  /* Black background with opacity */
  position: fixed;
  /* Full-screen overlay */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 10000px;
  z-index: 99;
  animation: fadeIn 1s;
  opacity: .75;
}

/* 
.overlay-shadow.out {
  animation: fadeOut 1s;
  background-color: rgba(0, 0, 0);
  z-index: -99;
  opacity: 0;
} */

.overlay-shadow.out {
  animation: fadeOut 1s forwards;
}

.overlay-shadow.hidden {
  z-index: -99;
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: .75;
  }
}

@keyframes fadeOut {
  from {
    opacity: .75;
  }

  to {
    opacity: 0;
  }
}

/* Styling for OrderDetailsMin.js */
.local-dispatch-order-details {
  /* border: 1px solid black; */
  box-sizing: 'border-box';
  background-color: 'white';
  box-shadow: 1px 1px 10px #00000020;
  border-radius: 5px;
  /* padding: 10px; */
}

.order-details-header {
  background-color: #244da1;
  padding: 10px 0px 7px 16px;
  border-radius: 5px 5px 0px 0px;

}

.order-details-hearder h3 {
  color: white;
  font-Size: 14px;
}

.order-details-header h3 {
  color: white;
  font-Size: 14px;
}

.order-content {
  padding: 10px 16px 0px 16px;
  background-color: white;
  border-radius: 6px;
}

.draggable-width {
  min-width: 367px;
  z-index: -999;
}

.ready-time {
  display: flex;
  align-items: center;
  padding: 3px;
  background-color: #f5f5f5;
  border-radius: 5px;
  margin-left: 0px;
  width: 40%;
  font-size: 9px;
}

.ready-time label {
  margin-right: 10px;
  /* font-size: 14px; */
  color: #333;
}

.ready-time .ready-date {
  flex-grow: 1;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-right: 10px;
}

.local-dispatch-section {
  display: flex;
  justify-content: space-around;
  padding-bottom: 10px;
  flex-wrap: wrap;
  gap: 10px;
  color: #244da1;
}

.local-dispatch-items {
  /* padding: 5px; */
  /* height: 100%; */
  /* margin: 5px; */
  box-shadow: 1px 1px 6px #00000030;
  border-radius: 6px;
  flex-grow: 1;
  flex-basis: 175px;

}

.dispatch-items-header {
  background-color: #244da1;
  padding: 10px 0px 3px;
  border-radius: 6px 6px 0px 0px;
  padding-bottom: 10px;
}

.dispatch-items-header h5 {
  /* text-align: center; */
  color: white;
  font-size: 11px;
  margin-left: 20px;
}

.dispatch-items-header-small {
  background-color: #244da1;
  padding: 5px 0px 3px;
  border-radius: 6px 6px 0px 0px;
  padding-bottom: 5px;
}

.dispatch-items-small {
  /* text-align: center; */
  color: white;
  font-size: 8px;
  margin-left: 10px;
}


.dispatch-items-content {
  padding: 10px 20px 10px 20px;
  /* background-color: #f5f5f5; */
  border-radius: 5px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: .5rem;
  font-size: 9px;

}

.form-col label {
  font-size: 7px;
}


.grid-col-span-2 {
  grid-column: span 2;
}

.grid-col-span-3 {
  grid-column: span 3;
}

.grid-col-span-4 {
  grid-column: span 4;
}

.form-row {
  display: flex;
  justify-content: space-between;
}

.form-col {
  flex: 1;
  float: left;
}

.form-col label {
  display: block;
  /* font-size: 12px; */
  color: #333;
}

.form-col input[type="text"] {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.dispatch-accessorial {
  /* width: 32.5%; */
  margin: 0px 0px 0px 0px;
  font-size: 9px;
  flex-grow: 1;
  flex-basis: 175px;

}

.dispatch-accessorial textarea {
  height: 30px;
  width: 100%;
  border: 1px #FFFFFF;
  border-radius: 6px;
  background-color: #f5f5f5;
}

.dispatch-accessorial h6 {
  font-size: 14px;
  color: #333;
}

.form-col-buttons button {
  margin-left: 5px;
  margin-bottom: 10px;
  margin-right: 5px;
  border-radius: 3px;
  color: white;
  background-color: #244da1;
  border: none;
  padding: 5px;
  font-size: 11px;
}

.max-field-label {
  width: 100%;
}


.sticky-header {
  /* position: fixed; */
  /* top: 80px;
  z-index: 98;
  width: 81.5%;
  height: 75px;
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 90%, rgba(255,255,255,0.75) 100%);
  padding: 20px 20px 20px 0px;
  margin-left: 10px; */
  /* justify-content: space-between; */
}

.contain-accessorial {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.driverList-menu div {
  width: 121.6px;
}

.cursor-move {
  cursor: move;
}